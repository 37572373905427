<template>
  <div class="competition">
    <div class="swiper-container" id="makeBanner">
      <div class="swiper-wrapper">
        <!-- <div class="swiper-slide">
          <banner />
        </div>-->
        <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
          <el-image :src="item.fileUrl" :alt="item.fileName" fit="cover"></el-image>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination1" slot="pagination"></div>
    </div>
    <div>
      <img style="max-width:100%;display:block;" v-for="item in contentImage" :key="item.id" :src="item.fileUrl" :alt="item.fileName" />
    </div>
    <!-- <div class="scroll-menu">
      <div class="item" @click="goMenu(1)">主题</div>
      <div class="item" @click="goMenu(2)">组织机构</div>
      <div class="item" @click="goMenu(3)">赛事流程</div>
      <div class="item" @click="goMenu(4)">奖项设置</div>
      <p><span></span></p>
    </div>
    <div class="text-item">
      <div class="content">
        <div class="c-title anime">
          介绍
          <span></span>
        </div>
        <div class="desc anime">
          <p>
            进一步激发工业设计创新创业活力，推动我省工业设计与制造业融合发展，加快工业设计创新，塑造“吉林设计”品牌，促进制造与设计融合，推进设计成果转化，发展新兴产业，提升工业设计产业化水平，吉林省工业设计协会决定，与吉林省华佰科技有限公司合作，举办2021“绿伞杯”吉林省工业设计大赛。
          </p>
        </div>
      </div>
    </div>
    <div class="text-item" id="menu2">
      <div class="content">
        <div class="c-title anime">
          组织机构
          <span></span>
        </div>
        <div class="desc anime c">
         <b>指导单位：</b>：<span>吉林省工业和信息化厅</span><br />

          <b>主办单位</b>：<span
            v-for="item in yz_vote_web_zhuban"
            :key="item.dictValue"
            >{{ item.remarks }}</span
          ><br />
          <b>承办单位：</b>：<span>吉林省工业设计协会</span><br />
          <b>协办单位</b>：<span
            v-for="item in yz_vote_web_xieban"
            :key="item.dictValue"
            >{{ item.remarks }}</span
          ><br />
          <b>赞助单位：</b>：<span>吉林省华佰科技有限公司</span><br />







          <b>技术支持单位</b>：惠享工业企业服务平台<br />
          <b>奖项支持单位</b>：易造科技（长春）有限公司
        </div>
      </div>
    </div>
    <div class="flow" id="menu3">
      <div class="content">
        <div class="c-title anime">赛事流程 <span></span></div>
        <div class="flow-main anime" v-if="!$store.state.isWap">
          <p>
            ------------------------------------------------------------------
          </p>
          <div class="item">
            <p>1</p>
            <span>注册报名</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item t">
            <span>作品提交</span>
            <p>2</p>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item">
            <p>3</p>
            <span>线上初评</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item t">
            <span>线上终评</span>
            <p>4</p>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item">
            <p>5</p>
            <span>获奖公示</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item t">
            <span>颁奖</span>
            <p>6</p>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item">
            <p>7</p>
            <span>佳作展</span>
          </div>
        </div>
        <div class="flow-main anime" v-if="$store.state.isWap">
          <div class="item">
            <p>1</p>
            <span>注册报名</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item t">
            <p>2</p>
            <span>作品提交</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item">
            <p>3</p>
            <span>线上初评</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item t">
            <p>4</p>
            <span>线上终评</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item">
            <p>5</p>
            <span>获奖公示</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item t">
            <p>6</p>
            <span>颁奖</span>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_c_r.png" alt="" />
          </div>
          <div class="item">
            <p>7</p>
            <span>佳作展</span>
          </div>
        </div>
      </div>
    </div>
    <div class="award" id="menu4">
      <div class="content">
        <div class="c-title anime">
          奖项设置
          <span></span>
        </div>
        <div class="desc anime c">
          本届大赛由吉林省工业协会主办、工业协会会员支持下，由惠享工业企业服务平台承办本次大赛。
        </div>
        <div class="list anime">
          <div class="item" v-for="item in awardList" :key="item.id">
            <p>{{ item.title }}</p>
            <p>{{ item.num }}</p>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
import Swiper from "@/assets/swiper/js/swiper.min.js";
import "@/assets/swiper/css/swiper.min.css";
export default {
  data() {
    return {
      contentImage: [],
      bannerList: [],
      awardList: [
        {
          id: 1,
          title: "最佳优秀设计奖",
          num: "1名",
          content: "3000元+证书+奖杯"
        },
        { id: 2, title: "金奖", num: "8名", content: "1000元+证书+奖杯" },
        { id: 3, title: "银奖", num: "10名", content: "800元+证书+奖杯" },
        { id: 4, title: "铜奖", num: "15名", content: "500元+证书" },
        { id: 5, title: "优秀奖", num: "20名", content: "证书" },
        { id: 6, title: "优秀指导教师奖", num: "10名", content: "证书" },
        { id: 7, title: "优秀组织奖", num: "6名", content: "奖牌" }
      ],
      yz_vote_web_zhuban: [],
      yz_vote_web_xieban: []
    };
  },
  methods: {
    goMenu(i) {
      let top = {
        1: 0,
        2: $("#menu2").offset().top - 190,
        3: $("#menu3").offset().top - 190,
        4: $("#menu4").offset().top - 190
      };
      $("body,html").animate({ scrollTop: top[i] }, 500);
    }
  },
  mounted() {
    this.$parent.finish ? this.$parent.finish() : null;

    this.$api.get("/api/v1/getYzVoteDefaultSetting").then(res => {
      if (res) {
        if (window.innerWidth < 1000) {
          this.bannerList =
            res.data.listindex_mobile_banner_image ||
            res.data.listindex_pc_banner_image;
          this.contentImage =
            res.data.listindex_mobile_huodongshuoming_image ||
            res.data.listindex_pc_huodongshuoming_image;
        } else {
          this.bannerList = res.data.listindex_pc_banner_image;
          this.contentImage = res.data.listindex_pc_huodongshuoming_image;
        }
        console.log(this.contentImage);
        new Swiper("#makeBanner", {
          autoplay: {
            delay: 3500,
            stopOnLastSlide: false,
            disableOnInteraction: true
          },
          observer: true,
          observeParents: true,
          pagination: {
            el: ".swiper-pagination1",
            clickable: true
          }
        });
        this.$sapi
          .get("/api/v1/getDictDataList-yz_vote_web_zhuban.json")
          .then(res => {
            this.yz_vote_web_zhuban = res;
          });
        this.$sapi
          .get("/api/v1/getDictDataList-yz_vote_web_xieban.json")
          .then(res => {
            this.yz_vote_web_xieban = res;
          });
        $(window).on("scroll", function() {
          $(".scroll-menu .item").each(function() {
            if ($("body,html").scrollTop() < $("#menu2").offset().top - 200) {
              $(".scroll-menu p").removeClass("on2 on3 on1");
            }
            if ($("body,html").scrollTop() > $("#menu2").offset().top - 200) {
              $(".scroll-menu p")
                .removeClass("on2 on3")
                .addClass("on1");
            }
            if ($("body,html").scrollTop() > $("#menu3").offset().top - 200) {
              $(".scroll-menu p")
                .removeClass("on1 on3")
                .addClass("on2");
            }
            if ($("body,html").scrollTop() > $("#menu4").offset().top - 200) {
              $(".scroll-menu p")
                .removeClass("on1 on2")
                .addClass("on3");
            }
          });
        });

        $('.competition-footer').hide()
      }
    });
  }
};
</script>
<style lang="scss">
.competition {
  color: white;
  .c-title {
    text-align: center;
    font-size: 30px;
    color: #fffefe;
    span {
      width: 61px;
      height: 4px;
      display: block;
      background-color: $c;
      box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
      margin: 20px auto 0;
    }
  }
  .text-item {
    margin-top: 5vw;
    .content {
      .desc {
        margin-top: 4vw;
        font-size: 20px;
        line-height: 3vw;
        letter-spacing: 2px;
        p {
          text-indent: 2em;
        }
        &.c {
          text-align: center;
        }
      }
    }
  }
  .flow {
    margin-top: 5vw;
    .flow-main {
      margin-top: 4vw;
      display: flex;
      justify-content: space-around;
      overflow: hidden;
      position: relative;
      align-items: center;
      & > p {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 20px;
        text-align: center;
        color: #ffffff;
        letter-spacing: 4px;
        line-height: 115px;
      }
      .icon {
        position: relative;
        z-index: 9;
      }
      .item {
        text-align: center;
        padding-top: 25px;
        position: relative;
        z-index: 9;
        &.t {
          padding-top: 0;
          padding-bottom: 25px;
        }
        p {
          width: 30px;
          height: 30px;
          background-image: linear-gradient(90deg, #2788ff 0%, #014d91 100%),
            linear-gradient(#014d91, #014d91);
          text-align: center;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          font-size: 18px;
          color: white;
          margin: 20px auto;
        }
        span {
          display: block;
          font-size: 20px;
        }
      }
    }
  }
  .award {
    margin-top: 5vw;
    .desc {
      margin-top: 4vw;
      font-size: 20px;
      line-height: 100px;
      letter-spacing: 2px;
      &.c {
        text-align: center;
      }
    }
    .list {
      display: flex;
      justify-content: space-around;
      .item {
        width: calc(92% / 7);
        background-color: #100f1e;
        border: solid 4px #014d91;
        padding: 25px 5px;
        @include box();
        p {
          margin-top: 25px;
          font-size: 16px;
          text-align: center;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
.scroll-menu {
  position: fixed;
  left: 5vw;
  top: 20vw;
  z-index: 999;
  padding-left: 30px;
  p {
    top: 5px;
    left: 0;
    bottom: 5px;
    position: absolute;
    border-left: 1px solid #e5e5e5;
    &.on1 {
      span {
        top: 69px;
      }
    }
    &.on2 {
      span {
        top: 138px;
      }
    }
    &.on3 {
      span {
        top: 207px;
      }
    }
    span {
      position: absolute;
      display: block;
      top: -2px;
      left: 50%;
      transition: 0.3s top;
      transform: translateX(-50%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $c;
    }
  }
  .item {
    font-size: 20px;
    color: #fffefe;
    font-weight: bold;
    line-height: 20px;
    margin-top: 50px;
    letter-spacing: 2px;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &.on {
      color: $c;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
@media (max-width: 1000px) {
  .competition {
    color: white;
    .c-title {
      text-align: center;
      font-size: 5vw;
      color: #fffefe;
      span {
        width: 12vw;
        height: 1vw;
        display: block;
        background-color: $c;
        box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
        margin: 3vw auto 0;
      }
    }
    .text-item {
      margin-top: 5vw;
      .content {
        .desc {
          margin-top: 4vw;
          font-size: 3.5vw;
          line-height: 6vw;
          letter-spacing: 2px;
          &.c {
            text-align: center;
          }
        }
      }
    }
    .flow {
      margin-top: 5vw;
      .flow-main {
        margin-top: 4vw;
        display: flex;
        justify-content: space-around;
        overflow: hidden;
        position: relative;
        align-items: center;
        flex-wrap: wrap;
        & > p {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          font-size: 4vw;
          text-align: center;
          color: #ffffff;
          letter-spacing: 4px;
          line-height: 23vw;
        }
        .icon {
          position: relative;
          z-index: 9;
        }
        .item {
          text-align: center;
          padding: 2vw 0 2vw;
          position: relative;
          z-index: 9;
          width: 30%;
          &.t {
            padding-top: 2vw;
            padding-bottom: 2vw;
          }
          p {
            width: 6vw;
            height: 6vw;
            background-image: linear-gradient(90deg, #2788ff 0%, #014d91 100%),
              linear-gradient(#014d91, #014d91);
            text-align: center;
            border-radius: 50%;
            text-align: center;
            line-height: 6vw;
            font-size: 4vw;
            color: white;
            margin: 0 auto 4vw;
          }
          span {
            display: block;
            font-size: 3vw;
          }
        }
      }
    }
    .award {
      margin-top: 5vw;
      .desc {
        margin-top: 4vw;
        font-size: 3.5vw;
        line-height: 1.5;
        letter-spacing: 2px;
        &.c {
          text-align: center;
        }
      }
      .list {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .item {
          width: calc(80% / 2);
          background-color: #100f1e;
          border: solid 1vw #014d91;
          padding: 3vw 2vw;
          margin-top: 4vw;
          @include box();
          p {
            margin-top: 3vw;
            font-size: 4vw;
            text-align: center;
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .scroll-menu {
    position: fixed;
    left: 5vw;
    top: 30vw;
    z-index: 999;
    padding-left: 4vw;
    p {
      top: 1.5vw;
      left: 0;
      bottom: 1.5vw;
      position: absolute;
      border-left: 1px solid #e5e5e5;
      &.on1 {
        span {
          top: 6.5vw;
        }
      }
      &.on2 {
        span {
          top: 15vw;
        }
      }
      &.on3 {
        span {
          top: 22vw;
        }
      }
      span {
        position: absolute;
        display: block;
        top: -2px;
        left: 50%;
        transition: 0.3s top;
        transform: translateX(-50%);
        width: 3vw;
        height: 3vw;
        border-radius: 50%;
        background-color: $c;
      }
    }
    .item {
      font-size: 4vw;
      color: #fffefe;
      font-weight: bold;
      line-height: 4vw;
      margin-top: 4vw;
      letter-spacing: 2px;
      opacity: 0.8;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      &.on {
        color: $c;
      }
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>